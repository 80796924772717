import { Languages } from "../../Languages";

export interface LanguageSlice {
  languageId: Languages.Ids;
}

export enum LanguageActionsTypes {
  SET_LANGUAGE_ACTION_TYPE = "SET_LANGUAGE_ACTION_TYPE",
}

export interface ActionSetLanguagePayload {
  languageId: Languages.Ids;
}
export interface ActionSetLanguage {
  type: LanguageActionsTypes.SET_LANGUAGE_ACTION_TYPE;
  payload: ActionSetLanguagePayload;
}

export type LanguageActions = ActionSetLanguage;
