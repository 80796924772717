export enum STORES {
  ALL_PROGRAMS = "allProgramsManager",
  AUTH_MANAGER = "authManager",
  LOADING_MANAGER = "loadingManager",
  STRIPE_MANAGER = "stripeManager",
  CHECKOUT_MANAGER = "checkoutManager",
  HASURA_PUBLICATIONS_MANAGER = "hasuraPublicationsManager",
  MY_PROGRAMS = "myProgramsManager",
  FREE_CHECKOUT_MANAGER = "freeCheckoutManager",
  LANGUAGE = "languageManager",
}

export const BlackListedStores = [
  STORES.ALL_PROGRAMS,
  STORES.AUTH_MANAGER,
  STORES.LOADING_MANAGER,
  STORES.STRIPE_MANAGER,
  STORES.CHECKOUT_MANAGER,
  STORES.HASURA_PUBLICATIONS_MANAGER,
  STORES.MY_PROGRAMS,
];
