export namespace Languages {
  export enum Ids {
    FR = "fr",
    EN = "en",
  }

  export const defaultLanguage = {
    id: Ids.FR,
    friendlyName: "Français",
  };

  export const supportedLanguages = {
    byId: {
      en: {
        id: Ids.EN,
        friendlyName: "English",
      },
      fr: {
        id: Ids.FR,
        friendlyName: "Français",
      },
    },
    allIds: [Ids.EN, Ids.FR],
  };

}
