import { createSelector } from "reselect";
import { STORES } from "../stores";
import { AuthSlice } from "./auth.types";

export const selectAuthManager = (state: any): AuthSlice =>
  state[STORES.AUTH_MANAGER];

export const selectIsAuthenticated = createSelector(
  [selectAuthManager],
  (authManager) => authManager.isAuthenticated
);

export const selectIsLoggedOut = createSelector(
  [selectAuthManager],
  (authManager) => authManager.isLoggedOut
);

export const selectIsLoggingIn = createSelector(
  [selectAuthManager],
  (authManager) => authManager.isLoggingIn
);

export const selectIsLoggingOut = createSelector(
  [selectAuthManager],
  (authManager) => authManager.isLoggingOut
);

export const selectUser = createSelector(
  [selectAuthManager],
  (authManager) => authManager.user
);

export const selectTokenResults = createSelector(
  [selectAuthManager],
  (authManager) => {
    return authManager.tokenResults;
  }
);

export const selectTokenExpirationTime = createSelector(
  [selectTokenResults],
  (tokenResults) => {
    if (!tokenResults?.expirationTime) {
      return null;
    } else {
      return new Date(tokenResults.expirationTime);
    }
  }
);

export const selectTokenIsValid = (now: Date) =>
  createSelector([selectTokenExpirationTime], (tokenExpirationTime) => {
    if (!tokenExpirationTime) {
      return false;
    } else {
      if (now.getTime() < tokenExpirationTime.getTime()) {
        return true;
      } else {
        return false;
      }
    }
  });

export const selectUserUid = createSelector([selectUser], (user) => {
  return user?.uid;
});

export const selectUserEmail = createSelector([selectUser], (user) => {
  return user?.email;
});

export const selectToken = createSelector(
  [selectTokenResults],
  (tokenResults) => {
    return tokenResults?.token;
  }
);
