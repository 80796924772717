import AuthActionTypes, {
  ActionAuthLoginStartPayload,
  ActionAuthLoginStart,
  ActionAuthLoginSuccessPayload,
  ActionAuthLoginSuccess,
  ActionAuthLoginFailurePayload,
  ActionAuthLoginFailure,
  ActionAuthLogoutStart,
  ActionAuthLogoutSuccess,
  ActionAuthLogoutFailurePayload,
  ActionAuthLogoutFailure,
  ActionRefreshTokenStart,
  ActionRefreshTokenSuccessPayload,
  ActionRefreshTokenSuccess,
  ActionRefreshTokenFailure,
  ActionRefreshTokenFailurePayload,
  ActionStartTokenRefresher,
  ActionRefreshFirebaseUserStart,
  ActionRefreshFirebaseUserStartPayload,
  ActionRefreshFirebaseUserSuccess,
  ActionRefreshFirebaseUserSuccessPayload,
  ActionRefreshFirebaseUserFailure,
  ActionRefreshFirebaseUserFailurePayload,
} from "./auth.types";

export const actionAuthLoginStart = (
  payload: ActionAuthLoginStartPayload
): ActionAuthLoginStart => ({
  type: AuthActionTypes.LOGIN_START,
  payload,
});

export const actionAuthLoginSuccess = (
  payload: ActionAuthLoginSuccessPayload
): ActionAuthLoginSuccess => ({
  type: AuthActionTypes.LOGIN_SUCCESS,
  payload,
});

export const actionAuthLoginFailure = (
  payload: ActionAuthLoginFailurePayload
): ActionAuthLoginFailure => ({
  type: AuthActionTypes.LOGIN_FAILURE,
  payload,
});

export const actionAuthLogoutStart = (): ActionAuthLogoutStart => ({
  type: AuthActionTypes.LOGOUT_START,
  payload: {},
});

export const actionAuthLogoutSuccess = (): ActionAuthLogoutSuccess => ({
  type: AuthActionTypes.LOGOUT_SUCCESS,
  payload: {},
});

export const actionAuthLogoutFailure = (
  payload: ActionAuthLogoutFailurePayload
): ActionAuthLogoutFailure => ({
  type: AuthActionTypes.LOGOUT_FAILURE,
  payload,
});

export const actionRefreshTokenStart = (): ActionRefreshTokenStart => ({
  type: AuthActionTypes.REFRESH_TOKEN_START,
  payload: {},
});

export const actionRefreshTokenSuccess = (
  payload: ActionRefreshTokenSuccessPayload
): ActionRefreshTokenSuccess => ({
  type: AuthActionTypes.REFRESH_TOKEN_SUCCESS,
  payload,
});

export const actionRefreshTokenFailure = (
  payload: ActionRefreshTokenFailurePayload
): ActionRefreshTokenFailure => ({
  type: AuthActionTypes.REFRESH_TOKEN_FAILURE,
  payload,
});

export const actionStartTokenRefresher = (): ActionStartTokenRefresher => ({
  type: AuthActionTypes.START_TOKEN_REFRESHER,
  payload: {},
});

export const actionRefreshFirebaseUserStart = (
  payload: ActionRefreshFirebaseUserStartPayload
): ActionRefreshFirebaseUserStart => ({
  type: AuthActionTypes.FIREBASE_REFRESH_USER_START,
  payload,
});

export const actionRefreshFirebaseUserSuccess = (
  payload: ActionRefreshFirebaseUserSuccessPayload
): ActionRefreshFirebaseUserSuccess => ({
  type: AuthActionTypes.FIREBASE_REFRESH_USER_SUCCESS,
  payload,
});

export const actionRefreshFirebaseUserFailure = (
  payload: ActionRefreshFirebaseUserFailurePayload
): ActionRefreshFirebaseUserFailure => ({
  type: AuthActionTypes.FIREBASE_REFRESH_USER_FAILURE,
  payload,
});
