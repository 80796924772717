export interface AuthSlice {
  isAuthenticated: boolean;
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  isLoggedOut: boolean;
  user: undefined | firebase.User;
  tokenResults: null | firebase.auth.IdTokenResult;
  tokenRefresherEnabled: boolean;
}

export enum AuthActionTypes {
  LOGIN_START = "LOGIN_START",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAILURE = "LOGIN_FAILURE",
  LOGOUT_START = "LOGOUT_START",
  LOGOUT_SUCCESS = "LOGOUT_SUCCESS",
  LOGOUT_FAILURE = "LOGOUT_FAILURE",
  REFRESH_TOKEN_START = "REFRESH_TOKEN_START",
  REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS",
  REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE",
  START_TOKEN_REFRESHER = "START_TOKEN_REFRESHER",
  FIREBASE_REFRESH_USER_START = "FIREBASE_REFRESH_USER_START",
  FIREBASE_REFRESH_USER_SUCCESS = "FIREBASE_REFRESH_USER_SUCCESS",
  FIREBASE_REFRESH_USER_FAILURE = "FIREBASE_REFRESH_USER_FAILURE",
}

export interface ActionAuthLoginStartPayload {
  user: firebase.User;
}
export interface ActionAuthLoginStart {
  type: AuthActionTypes.LOGIN_START;
  payload: ActionAuthLoginStartPayload;
}

export interface ActionAuthLoginSuccessPayload {
  user: firebase.User;
  tokenResults: firebase.auth.IdTokenResult;
}
export interface ActionAuthLoginSuccess {
  type: AuthActionTypes.LOGIN_SUCCESS;
  payload: ActionAuthLoginSuccessPayload;
}

export interface ActionAuthLoginFailurePayload {
  error: Error;
}
export interface ActionAuthLoginFailure {
  type: AuthActionTypes.LOGIN_FAILURE;
  payload: ActionAuthLoginFailurePayload;
}

export interface ActionAuthLogoutStartPayload {}
export interface ActionAuthLogoutStart {
  type: AuthActionTypes.LOGOUT_START;
  payload: ActionAuthLogoutStartPayload;
}

export interface ActionAuthLogoutSuccessPayload {}
export interface ActionAuthLogoutSuccess {
  type: AuthActionTypes.LOGOUT_SUCCESS;
  payload: ActionAuthLogoutSuccessPayload;
}

export interface ActionAuthLogoutFailurePayload {}
export interface ActionAuthLogoutFailure {
  type: AuthActionTypes.LOGOUT_FAILURE;
  payload: ActionAuthLogoutFailurePayload;
}

export interface ActionRefreshTokenStartPayload {}
export interface ActionRefreshTokenStart {
  type: AuthActionTypes.REFRESH_TOKEN_START;
  payload: ActionRefreshTokenStartPayload;
}

export interface ActionRefreshTokenSuccessPayload {
  tokenResults: firebase.auth.IdTokenResult;
}
export interface ActionRefreshTokenSuccess {
  type: AuthActionTypes.REFRESH_TOKEN_SUCCESS;
  payload: ActionRefreshTokenSuccessPayload;
}

export interface ActionRefreshTokenFailurePayload {
  error: Error;
}
export interface ActionRefreshTokenFailure {
  type: AuthActionTypes.REFRESH_TOKEN_FAILURE;
  payload: ActionRefreshTokenFailurePayload;
}

export interface ActionStartTokenRefresherPayload {}
export interface ActionStartTokenRefresher {
  type: AuthActionTypes.START_TOKEN_REFRESHER;
  payload: ActionStartTokenRefresherPayload;
}

export interface ActionRefreshFirebaseUserStartPayload {
  user: firebase.User;
}
export interface ActionRefreshFirebaseUserStart {
  type: AuthActionTypes.FIREBASE_REFRESH_USER_START;
  payload: ActionRefreshFirebaseUserStartPayload;
}

export interface ActionRefreshFirebaseUserSuccessPayload {
  user: firebase.User;
  idTokenResult: firebase.auth.IdTokenResult;
}
export interface ActionRefreshFirebaseUserSuccess {
  type: AuthActionTypes.FIREBASE_REFRESH_USER_SUCCESS;
  payload: ActionRefreshFirebaseUserSuccessPayload;
}

export interface ActionRefreshFirebaseUserFailurePayload {
  error: Error;
}
export interface ActionRefreshFirebaseUserFailure {
  type: AuthActionTypes.FIREBASE_REFRESH_USER_FAILURE;
  payload: ActionRefreshFirebaseUserFailurePayload;
}

export type AuthActions =
  | ActionAuthLoginStart
  | ActionAuthLoginSuccess
  | ActionAuthLoginFailure
  | ActionAuthLogoutStart
  | ActionAuthLogoutSuccess
  | ActionAuthLogoutFailure
  | ActionRefreshTokenStart
  | ActionRefreshTokenSuccess
  | ActionRefreshTokenFailure
  | ActionStartTokenRefresher
  | ActionRefreshFirebaseUserStart
  | ActionRefreshFirebaseUserSuccess
  | ActionRefreshFirebaseUserFailure;

export default AuthActionTypes;
